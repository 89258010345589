import Base, {
  ModelAPIIndexFieldParam,
  ModelAPIIndexMeta,
  ModelAPIIndexParams,
} from './Base';

export interface CoinSettingAttrs {
  id: number;
  amount: number;
  limit: number;
  coinableType: string;
  slug: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  description: string;
  icon: string;
}

export default class CoinSetting extends Base<CoinSettingAttrs> {
  static async fetch(
    params?: Partial<Record<keyof CoinSettingAttrs, ModelAPIIndexFieldParam>> &
      ModelAPIIndexParams
  ) {
    const response = await this.$api.get<{
      data: CoinSettingAttrs[];
      meta: ModelAPIIndexMeta;
    }>('v1/coin-settings?isActive=1', { params });
    const data = response.data.data;
    const meta = response.data.meta;

    return { data, meta };
  }

  static async fetchBySlug(slug: string) {
    const { data } = await this.fetch({ slug });
    return data[0];
  }

  getThumbnailURL(width: number, height?: number) {
    if (!this.data.icon) {
      const $img = useImage();
      return $img('/assets/img/articles/image.jpg', { width, height });
    }

    return imageUtil.getThumbnailURL(this.data.icon, width, height);
  }
}
